<template>
<el-container style="height: calc(93.5vh)">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      添加预约
    </el-header>
    <el-header class="header">
      <div class="time">
        <span class="el-icon-arrow-left"></span>
        <el-date-picker 
          v-model="timePicker" 
          :editable="false" 
          type="date" 
          placeholder="选择日期" 
          align="center"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          prefix-icon="none" clear-icon="none">
        </el-date-picker>
        <span class="el-icon-arrow-right"></span>
      </div>
    </el-header>
      <el-container style="width:100%; height:calc(87vh);">
        <el-aside class="aside" width="150px">
          <el-menu class="menu">
            <el-menu-item v-for=" (item,index) in item" :key="index">
              <template slot="title">
                <div :class="activeItem===index?'nav1':'nav'" @click="ThisYuanGong(item,index)">
                  <img :src="$getimgsrc(item.avatar)" alt="" style="height: 35px;width: 35px;border-radius: 50%;margin-right: 5px;">
                  <span>{{item.realname}}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main class="main">
            <el-row :gutter="15" style="padding:10px 10px 0 0;">
              <el-radio-group v-if="item[activeItem]">
                <el-col class="cursor" :span="rowCol" v-for="(item1 ,i) in item[activeItem].time" :key="i">
            
                  <div class="order-card"
                    @click="addAbout(i,item1,item[activeItem])">
                    <div class="card-name">{{ item1.time }}</div>
                    <div class="btm">
                      <div class="btm-time"><el-radio :label="item1" :disabled="item1.per.length != 0"><br></el-radio></div>
                      <div class="btm-price">
                      
                        <strong v-if="item1.per.length != 0">已预约</strong>
                        <strong v-if="item1.per.length == 0" style="color: #409eff;">未预约</strong>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-radio-group>
            </el-row>
          </el-main>
        </el-container>
      </el-container>

      
     
  </el-container>

</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      timePicker:  new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
      aboutTime: null,
      item: [],
      activeItem: 0,
      activeItemList: [],
      thisyuangong: {},
      rowCol: 6,
    };
  },
  watch: {
    innerWidth(val) {
      if (val >= 1440) {
        this.rowCol = 6;
      } else if (val >= 1180) {
        this.rowCol = 8;
      } else if (val >= 900) {
        this.rowCol = 12;
      } else if (val <= 900) {
        this.rowCol = 24;
      }
    },
    timePicker() {
      this.getLookInfo();
    },
  },
  mounted() {
    var that = this;
    if (that.innerWidth >= 1440) {
      that.rowCol = 6;
    } else if (that.innerWidth >= 1180) {
      that.rowCol = 8;
    } else if (that.innerWidth >= 900) {
      that.rowCol = 12;
    } else if (that.innerWidth <= 900) {
      that.rowCol = 24;
    }
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.innerWidth = window.fullWidth; // 宽
      })();
    };
    this.getLookInfo();
  },
  methods: {
    ...mapMutations(["updataeAboutTime"]),
    // 返回上级
    returnOn() {
      this.$router.go(-1);
    },
    msgShow() {
      this.$message("此时段已有预约或未排班请预约其他时间段");
    },
    //选中一级服务分类
    ThisYuanGong(item, index) {
      this.thisyuangong = item;
      // this.thistime = null
      this.activeItem = index;
    },
    //获取看板信息
    getLookInfo() {
      this.$http
        .post("api/store/booked/paiworklistdetail", {
          time: this.timePicker,
        })
        .then((res) => {
          this.item = res.data.data;
          this.thisyuangong = this.item[0];
        });
    },
    addAbout(i, time, item) {
      if(time.per.length != 0){
        return this.msgShow()
      }
      this.aboutTime = i;
      let a={
        time:time.time
      }
      delete item.time;
      a={...a,...item}
      a.timePicker=this.timePicker
      this.updataeAboutTime(a);
      // console.log();
      // this.$message({ type: 'success',message:"预约成功"});
      this.$router.go(-1);
      
    },
  },
};
</script>
<style lang="less" scoped>
// 头部
.el-header {
  position: relative;
  z-index: 1;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: #333;
  height: 50px !important;
  line-height: 50px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;

  .left {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #46a6ff;
    font-size: 24px;
  }
}

// 左侧导航
/deep/ .aside.el-aside {
  min-height: calc(93.5vh);
  background-color: #fff;

  &:first-child {
    padding-top: 30px;
  }

  .time.el-icon-date {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    color: #666;
    font-size: 12px;

    &::before {
      margin-right: 5px;
    }
  }

  .menu {
    background-color: #fff;

    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: #fff;
    }
  }

  .nav {
    text-align: center;

    span {
      border: 1px solid transparent;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }

  .nav1 {
    text-align: center;

    span {
      color: #409eff;
      border: 1px solid #409eff;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }
}

// 左侧商品卡片
.main {
  background-color: #f9f9f9;

  .alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff5e6;
    color: #ff9900;
    padding: 0 10px;
    margin: 15px 0;
    font-size: 12px;
    border: 1px solid #ffebcc;

    .left {
      line-height: 40px;

      &::before {
        margin-right: 5px;
      }
    }

    .right {
      border: 1px solid #ff9900;
      color: #ff9900;
      line-height: 25px;
      padding: 0 15px;
      border-radius: 5px;
    }
  }

  .card {
    background-color: #fff;
    box-shadow: 0 0 2px #ccc;
    margin-bottom: 15px;
    padding: 10px;

    .title {
      margin-bottom: 10px;
      color: #666;
      font-weight: bold;
    }

    .box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .time-box {
        text-align: center;
        line-height: 30px;
        width: 120px;
        font-size: 12px;
        color: #666;
        border: 1px solid #f1f1f1;
        border-radius: 50px;
        margin: 10px;
      }

      .time-box1 {
        background-color: #f2f2f2;
        color: #ccc;
      }

      .time-box2 {
        border: 1px solid #409eff;
        background-color: #409eff;
        color: #fff;
      }
    }
  }
}

/deep/ .menu.el-menu {
  border-right: none !important;
  background-color: #f9f9f9;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #f9f9f9;
}

.main.el-main {
  padding: 0 10px !important;
}



.el-icon-arrow-left,
.el-icon-arrow-right {
  font-weight: bold;
  color: #666;
}

/deep/ .el-date-editor.el-input {
  width: 120px;

  .el-input__inner {
    border: 0;
    background-color: transparent;
    text-align: center;
    color: #409eff;
    padding: 0;
  }
}
// 头部
.el-header {
  position: relative;
  z-index: 1;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: #333;
  height: 50px !important;
  line-height: 50px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;

  .left {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #46a6ff;
    font-size: 24px;
  }
}

// 左侧导航
.aside {
  min-height: calc(93.5vh);
  background-color: #f9f9f9;

  &:first-child {
    padding-top: 30px;
  }

  .nav {
    text-align: center;

    span {
      border: 1px solid transparent;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }

  .nav1 {
    text-align: center;

    span {
      color: #409eff;
      border: 1px solid #409eff;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }
}
.order-card {
  box-shadow: 0px 0px 4px #ccc;
  margin: 8px 0;
  padding: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  overflow: hidden;
  border: 1px solid #f4f4f4;

  .card-name {
    font-size: 14px;
    color: #666;
    margin-bottom: 30px;
  }

  .btm {
    color: #999;
    font-size: 12px;

    .btm-time {
      float: left;
    }

    .btm-price {
      float: right;

      strong {
        margin-left: 10px;
        font-size: 14px;
        color: #f56c6c;
      }
    }
  }
}

.order-card1 {
  border: 1px solid #409eff;
  background-color: #e6eff9;
  box-shadow: none;
}
// 左侧商品卡片
.main {
  background-color: #f9f9f9;
}

// 右侧订单
.right-main {
  background-color: #fff;
  height: calc(65vh);

  .content {
    padding: 10px 10px;

    .about-info {
      .info-title {
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .user-info {
        padding: 0 10px;
        background-color: #f4f4f4;
        border-radius: 8px;
        margin-bottom: 10px;
        // height: 82px;
        overflow: hidden;

        .tel,
        .name,
        .teacher,
        .about-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .teacher,
        .about-time {
          .left,
          .right {
            display: flex;
            align-items: center;
            height: 41px;
          }

          .right {
            width: 70%;
            min-width: 120px;
          }
        }

        // .tel,
        // .teacher {
        //   // border-bottom: 1px solid #e9e9e9;
        // }

        .tel,
        .name {
          box-sizing: border-box;

          /deep/ .el-input {
            .el-input__inner {
              border: none;
              text-align: right;
              font-size: 12px;
              background-color: #f4f4f4;
              padding-right: 0;
            }
          }
        }

        .left {
          color: #666;
          font-size: 12px;
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          line-height: 40px;
          color: #666;
          font-size: 12px;

          .img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .check-service {
      color: #666;

      .service-title {
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .service-list {
        .card {
          line-height: 24px;
          box-shadow: 0 0 4px #ccc;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;

          .title,
          .time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .title {
            .el-icon-circle-close {
              color: #999;
              font-size: 16px;
            }
          }

          .time {
            .left {
              font-size: 12px;
              color: #999;
            }

            .right {
              .del {
                font-size: 12px;
                color: #999;
                text-decoration-line: line-through;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/ .el-dialog {
  min-width: 400px;
}

.dialog {
  /deep/.el-dialog__body {
    height: 400px;
  }

  .alert {
    color: #ff9900;
    margin-bottom: 20px;
  }

  .cell-list {
    width: 100%;

    /deep/ .el-radio-group {
      width: 100%;
      position: relative;

      .el-radio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #f2f2f2;
        padding: 10px;
        margin-bottom: 10px;
        margin-right: 0;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50%;
          }
        }

        .el-radio__input {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

/deep/ .el-footer {
  padding: 0 !important;
  height: auto !important;

  .order-bottom {
    padding: 10px 10px 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #666;
    border: 1px solid #e6e6e6;
    border-left: none;

    .btm-t {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 0;

      .t-left {
        b {
          color: #f56c6c;
        }
      }
    }

    .btm-c {
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 10px;

      .el-input .el-input__inner {
        border: none !important;
      }
    }

    .btm-b {
      display: flex;
      justify-content: center;

      .el-button {
        width: 100%;
      }
    }
  }
}

/deep/ .menu.el-menu {
  border-right: none !important;
  background-color: #f9f9f9;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #f9f9f9;
}

.main.el-main {
  padding: 0 10px !important;
}

// 左侧商品卡片
/deep/ .el-main.left-main {
  padding-bottom: 20px !important;
}

// 右侧订单
/deep/ .order-main.el-main {
  padding: 0 0 10px !important;
  width: 100%;
  position: relative;
  background-color: #fff;
}

/deep/ .order-header.el-header {
  height: auto !important;
  width: 100% !important;
  padding: 0 !important;
  border-bottom: none;
}

.order-box {
  padding: 0 10px 10px;
  box-sizing: border-box;
  background-color: #fff;

  .order-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-btn {
      width: 70%;
      line-height: 60px;

      span {
        width: 20%;
        font-size: 12px;
        padding: 5px 5px;
        color: #409eff;
        border: 1px solid #409eff;
        border-radius: 5px;
        margin-left: 10px;
      }
    }

    .right {
      position: relative;
      right: 30px;
      color: #409eff;

      span {
        position: absolute;
        top: 5px;
        right: -15px;
        width: 22px;
        height: 22px;
        font-size: 12px;
        text-align: center;
        line-height: 22px;
        color: #fff;
        background-color: #f56c6c;
        border-radius: 100%;
      }
    }
  }

  .order-vip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    overflow: hidden;
    padding-right: 10px;

    img {
      float: left;
      width: 40px;
      height: 40px;
      margin: 10px 15px;
    }

    .name {
      float: left;
      color: #999;
    }

    b {
      float: right;
      color: #ccc;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
    }
  }
}
.order {
  background-color: #fff;
  padding: 0 10px;

  .order-body {
    .goods-card {
      background-color: #fff;
      box-shadow: 0 0 4px #ccc;
      border-radius: 10px;
      margin-top: 10px;
      padding: 10px;
      color: #666;

      .card-t {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .t-name {
          width: 70%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        span {
          display: inline-block;
          margin-left: 10px;
          color: #ccc;
        }
      }

      .card-c {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .c-price {
          font-size: 12px;
          color: #999;

          span {
            color: #666;
            font-size: 14px;
          }
        }
      }

      .card-b {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .b-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 30px;
            margin-right: 10px;
          }
        }

        .b-change {
          color: #999;
          font-size: 12px;

          .icon-arrow-right-bold {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
